@import '/src/scss/colors';

.App {
    text-align: center;
    height: 100%;
    min-height: 100%;
}

.wrapper {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0 19px;

    .stars {
        background-image: url('./assets/images/png/application/stars.png');
        position: absolute;
        background-size: 100% 100%;
        top: -319px;
        width: 238%;
        max-width: 1007px;
        height: 711px;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        z-index: -1;
    }

    &::before {
        content: url(./assets/images/svg/content-radius.svg);
        position: fixed;
        top: 36px;
        left: -17px;
        z-index: 1;
        transform: rotate(-90deg) scale(0.1);
    }

    &::after {
        content: url(./assets/images/svg/content-radius.svg);
        position: fixed;
        top: 36px;
        right: -17px;
        z-index: 1;
        transform: scale(0.1);
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .overlay {
        position: fixed;
        background-color: rgba($nav-background-color, 0.7);
        opacity: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        top: 64px;
        transition: opacity 0.5s;
        visibility: hidden;

        &.blur {
            opacity: 90%;
            visibility: visible;
        }
    }

    .bg {
        position: fixed;
        left: 19px;
        top: 70px;
        right: 19px;
        bottom: 0;
        background: url(./assets/images/jpg/background.jpg);
        background-size: 100% 100%;
        z-index: -1;
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
        opacity: 1;
        transition: opacity 0.5s;
        min-height: 100vh;
        &.hide-content {
            z-index: -1;
            opacity: 0;
            transition: opacity 0.5s;
        }
    }
}

@media only screen and (min-width: 720px) {
    .wrapper {
        .stars {
            top: -236px;
            left: unset;
            width: 207%;
            max-width: unset;
            height: 619px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .wrapper {
        .stars {
            width: calc(100vw - 38px);
            max-width: 1920px;
            height: 801px;
            top: -302px;
            left: unset;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .wrapper {
        padding: 0;

        &::before {
            left: -36px;
            top: 49px;
        }

        &::after {
            right: -36px;
            top: 49px;
        }

        .bg {
            background: url(./assets/images/png/background-mobile.png);
            background-size: 100% 100%;
            left: 0;
            right: 0;
        }
    }
}

@media only screen and (max-width: 720px) {
    .wrapper {
        .bg {
            background: url(./assets/images/png/background-mobile-xs.png);
            background-size: 100% 100%;
            top: 84px;
            left: 0;
            right: 0;
        }
        .content {
            margin-top: 84px;
        }
    }
}
