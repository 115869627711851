@import '/src/scss/fonts';
@import '/src/scss/colors';

html,
#root {
    height: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    color: $white;
    background-color: $black;
}
