@font-face {
    font-family: 'BlenderPro-Bold';
    src: local('BlenderPro-Bold'), url(../assets/fonts/BlenderPro-Bold.ttf) format('truetype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BlenderPro-Medium';
    src: local('BlenderPro-Medium'), url(../assets/fonts/BlenderPro-Medium.ttf) format('truetype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BlenderPro-Book';
    src: local('BlenderPro-Book'), url(../assets/fonts/BlenderPro-Book.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'SFPro';
    src: local('SFPro'), url(../assets/fonts/SFPro.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'SFCompact';
    src: local('SFCompact'), url(../assets/fonts/SFCompact.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'SFCompact-Display';
    src: local('SFCompact-Display') url(../assets/fonts/SFCompact-Display-Medium.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(../assets/fonts/Inter-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Bold';
    src: local('Inter-Bold'), url(../assets/fonts/Inter-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Black';
    src: local('Inter-Black'), url(../assets/fonts/Inter-Black.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Light';
    src: local('Inter-Light'), url(../assets/fonts/Inter-Light.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Medium';
    src: local('Inter-Medium'), url(../assets/fonts/Inter-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Semi-Bold';
    src: local('Inter-Semi-Bold'), url(../assets/fonts/Inter-SemiBold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-Bold-Oblique';
    src: local('Futura-Bold-Oblique'), url(../assets/fonts/FuturaCondensedBoldOblique.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-Heavy-Oblique';
    src: local('Futura-Heavy-Oblique'), url(../assets/fonts/Futura-Heavy-Oblique.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-Extra-Bold-Oblique';
    src: local('Futura-Extra-Bold-Oblique'),
        url(../assets/fonts/futura-pt-cond-extrabold-oblique.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-PT-Bold-Oblique';
    src: local('Futura-PT-Bold-Oblique'), url(../assets/fonts/Futura-PT_Cond-Bold-Oblique.woff) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-PT-Extra-Bold-Oblique';
    src: local('Futura-PT-Extra-Bold-Oblique'),
        url(../assets/fonts/Futura-PT_Cond-ExtraBold-Oblique.woff) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-Extra-Bold-Oblique';
    src: local('Futura-Extra-Bold-Oblique'),
        url(../assets/fonts/futura-pt-cond-extrabold-oblique.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Futura-PT_Cond-ExtraBold';
    src: local('Futura-PT_Cond-ExtraBold'),
        url(../assets/fonts/Futura-PT_Cond-ExtraBold.otf) format('opentype');
    font-display: swap;
}