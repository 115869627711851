$primary-color: #00dcb9;
$primary-color-cta: #06bda0;
$secondary-color: #6436da;
$white: #ffffff;
$white-low-contrast: rgba($white, 0.5);
$gray: #a3a2a6;
$black: #000000;
$transparent-black: rgba(255, 255, 255, 0);
$disabled-button: #c4c4c4;
$shadow-effect-color: #8effed;
$nav-background-color: #04092e;
$dark-aqua: #007563;
$red: #fc0303;
$input-error-red: #f15151;
$input-error-orange: #fda63f;
$wild-sand: "#F5F4F4";
$turquoise: #00DBBA;
